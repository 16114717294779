import "@webscopeio/react-textarea-autocomplete/style.css";
import "emoji-mart/css/emoji-mart.css";
import { ReclaimAppProps } from "next/app";
import Head from "next/head";
import React from "react";
import { App } from "../components/App";
import { AnalyticsContextProvider } from "../context/AnalyticsContext";
import { AppContextProvider } from "../context/AppContext";
import { DomainContext } from "../context/DomainContext";
import { UserContextProvider } from "../context/UserContext";
import { LocalStorageSharedContextProvider } from "../hooks/useLocalStorage";
import "../index.css";
import "../lib/wdyr";
import { reclaim } from "../reclaim-api/index";

const HOST = process.env.NEXT_PUBLIC_VERCEL_URL || "app.reclaim.ai";

function MyApp({ Component, pageProps, err }: ReclaimAppProps) {
  let ogTitle: string = pageProps.ogTitle ? pageProps.ogTitle : "Reclaim | A smart friend for your calendar";
  let ogDescription: string = pageProps.ogDescription
    ? pageProps.ogDescription
    : "Reclaim automatically blocks flexible time on your calendar to keep you focused on the things that matter most.";
  let ogImage: string = pageProps.ogImage ? pageProps.ogImage : `https://${HOST}/img/opengraph/general.png`;

  return (
    <>
      <Head>
        <title>Reclaim – A smart friend for your calendar</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1.0, width=device-width" />

        {/* <!-- Primary Meta Tags --> */}
        <meta name="title" content="Reclaim | A smart friend for your calendar" key="title" />
        <meta
          name="description"
          content="Reclaim automatically blocks flexible time on your calendar to keep you focused on the things that matter most."
          key="description"
        />

        {/**
         * Social Sharing Metadata... this is in _app.tsx rather than _document.tsx because here it can be overridden
         * as discussed here: https://github.com/vercel/next.js/issues/9794
         *
         * If you wish to override any of these meta tags, the following keys need to be used:
         *
         *  - ogType
         *  - ogUrl
         *  - ogTitle
         *  - ogDescription
         *  - ogImage
         *  - twitterCard
         *  - twitterUrl
         *  - twitterTitle
         *  - twitterDescription
         *  - twitterImage
         *
         *  To test these out, the following URLs are handy:
         *
         *  https://ogp.me/
         *  https://cards-dev.twitter.com/validator
         *  https://www.linkedin.com/post-inspector/inspect
         */}

        {/*
         * The above note seems incorrect
         * To override og meta tags we must use getServerSideProps
         * As discussed here: https://github.com/vercel/next.js/issues/35172
         * C.K. 11/2022
         */}

        {/* OpenGraph / Slack / etc */}
        <meta name="og:type" content="website" key="ogType" />
        <meta name="og:url" content={`https://${HOST}/`} key="ogUrl" />

        <meta name="og:title" property="og:title" key="ogTitle" content={ogTitle} />
        <meta name="og:description" property="og:description" key="ogDescription" content={ogDescription} />
        <meta name="og:image" property="og:image" key="ogImage" content={ogImage} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" key="twitterCard" />
        <meta name="twitter:url" content={`https://${HOST}/`} key="twitterUrl" />
        <meta name="twitter:title" content="Reclaim | A smart friend for your calendar" key="twitterTitle" />
        <meta
          name="twitter:description"
          content="Reclaim automatically blocks flexible time on your calendar to keep you focused on the things that matter most."
          key="twitterDescription"
        />
        {/* Why an absolute URL pointing to app.reclaim.ai? because when we left that out, LinkedIn was happy but Twitter couldn't process it */}
        <meta name="twitter:image" content={`https://${HOST}/img/opengraph/general.png`} key="twitterImage" />
        {/***** END SOCIAL SHARING METADATA *****/}
      </Head>

      <DomainContext.Provider value={reclaim}>
        <AnalyticsContextProvider>
          <UserContextProvider>
            <AppContextProvider>
              <LocalStorageSharedContextProvider>
                <App>
                  <Component {...pageProps} err={err} />
                </App>
              </LocalStorageSharedContextProvider>
            </AppContextProvider>
          </UserContextProvider>
        </AnalyticsContextProvider>
      </DomainContext.Provider>
    </>
  );
}

export default MyApp;
